function getAllUrlParams(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split("#")[0];

        // split our query string into its component parts
        var arr = queryString.split("&");

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split("=");

            // in case params look like: list[]=thing1&list[]=thing2
            var paramNum = undefined;
            var paramName = a[0].replace(/\[\d*\]/, function(v) {
                paramNum = v.slice(1, -1);
                return "";
            });

            // set parameter value (use 'true' if empty)
            var paramValue = typeof a[1] === "undefined" ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            paramValue = paramValue.toLowerCase();

            // if parameter name already exists
            if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === "string") {
                    obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === "undefined") {
                    // put the value on the end of the array
                    obj[paramName].push(paramValue);
                }
                // if array index number specified...
                else {
                    // put the value at that index number
                    obj[paramName][paramNum] = paramValue;
                }
            }
            // if param name doesn't exist yet, set it
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
}

jQuery(document).ready(function($) {
    /*$url = $("#link_first_vehicules").attr('href');
                $.ajax({
                    type: "POST",
                    url: $url,
                    success: function (data) {

                        $('#result_vehicules').html(data);

                        $('img.loader').hide();
                        $('#see_all_vehicules').show();
                         $(".sticky-marque").stick_in_parent({offset_top: 0});
    $(".sticky-model").stick_in_parent({offset_top: 50});
                    }
                }, "html");
    */
    //BOOTSTRAP PANEL
    $("#myTabs a").click(function(e) {
        e.preventDefault();
        $(this).tab("show");
    });

    //STIKIKI
    // $(".sticky-marque").stick_in_parent({offset_top: 0});
    // $(".sticky-model").stick_in_parent({offset_top: 50});

    // see_all_vehicules
    $(document).on("click", "#see_all_vehicules", function(e) {
        $("img.loader").show();

        $url = $(this).attr("href");
        $.ajax(
            {
                type: "POST",
                url: $url,
                success: function(data) {
                    $("#result_vehicules").html(data);

                    $(".sticky-marque").stick_in_parent({ offset_top: 0 });
                    $(".sticky-model").stick_in_parent({ offset_top: 50 });
                }
            },
            "html"
        );

        $("img.loader").hide();

        return false;
    });

    // Page
    /*   $(document).on('click',"ul.pagination a", function(e) {

           $url = $(this).attr('href');
           $page = getAllUrlParams($url).page;
           $('.tab-content form input[name="page"]').val($page);
           $('.tab-pane.active form').submit();
           return false;
       });*/

    // TOGGLE SECTION
    // $(document).on('click', ".section-toggle h3", function (e) {
    //     e.preventDefault();
    //     if ($(this).hasClass('active')) {
    //         $(".section-toggle h3").removeClass('active');
    //         $(".section-toggle h3").next().slideUp();
    //         $(this).next().slideUp();
    //         $(this).removeClass('active');
    //     } else {
    //         $(".section-toggle h3").removeClass('active');
    //         $(".section-toggle h3").next().slideUp();
    //         $(this).next().slideDown();
    //         $(this).addClass('active');
    //     }
    // });

    //NAVBAR Dropdown
    var activeLang = $(".mod-languages .active");
    $("#navbarDropdown >ul").append(activeLang);

    //Mobile filters toggle
    function collapseFilters() {
        if ($("#search-lvl1").hasClass("collapsed")) {
            $("#search-lvl1").removeClass("collapsed");
            $("#search-lvl2").addClass("collapsed");
        } else {
            $("#search-lvl1").addClass("collapsed");
            $("#search-lvl2").removeClass("collapsed");
        }
    }

    function displayMobileSidebar_search() {
        $("#search-lvl1").toggleClass("mobile-sidebar-hidden");
    }

    function displayMobileSidebar_filters() {
        $("#search-lvl2").toggleClass("mobile-sidebar-hidden");
    }

    $(document).on("click", "#open-filters-button", function() {
        displayMobileSidebar_search();
    });
    $(document).on("click", "#open-filters-button2", function() {
        displayMobileSidebar_filters();
    });

    $(document).on("click", "#search-lvl1.collapsed", function() {
        collapseFilters();
    });

    $(document).on("click", "#search-lvl2.collapsed", function() {
        collapseFilters();
    });

    $(document).on("click", "#vl-btn", function() {
        $("#vl-btn").addClass("active");
        $("#vul-btn").removeClass("active");
        $("#pl-btn").removeClass("active");

        $("#vehicule-category").val("VL");
        $("#vehicule-category").change();
        $("#searchcatalogue").submit();
    });

    $(document).on("click", "#vul-btn", function() {
        $("#vl-btn").removeClass("active");
        $("#vul-btn").addClass("active");
        $("#pl-btn").removeClass("active");

        $("#vehicule-category").val("VUL");
        $("#vehicule-category").change();
        $("#searchcatalogue").submit();
    });

    $(document).on("click", "#pl-btn", function() {
        $("#vl-btn").removeClass("active");
        $("#vul-btn").removeClass("active");
        $("#pl-btn").addClass("active");

        $("#vehicule-category").val("PL");
        $("#vehicule-category").change();
        $("#searchcatalogue").submit();
    });

    //input New Products
    $(document).on("click", "input#new", function(e) {
        $(this)
            .closest("form")
            .submit();
    });

    // $('.select2-criteria').select2({
    //     placeholder: "Choisir",
    //     allowClear: true
    // });
    // /* The following mess is a result of Select2's behavior of triggering the opening event twice upon unselecting an item. */
    // $('.select2-criteria').on('select2:unselecting', function (e) {
    //     $(this).data('unselecting1', true);
    //     $(this).data('unselecting2', true);
    // });
    // $('.select2-criteria').on('select2:open', function (e) {
    //     var unselecting1 = $(this).data('unselecting1');
    //     var unselecting2 = $(this).data('unselecting2');
    //     if (unselecting1 || unselecting2) {
    //         $(this).select2('close');
    //         if (unselecting1) {
    //             $(this).data('unselecting1', false);
    //         } else {
    //             $(this).data('unselecting2', false);
    //         }
    //     }
    // });
    // /* End of mess.*/

    //Popup Panier
    window.setTimeout(function() {
        $(".helpPopup").addClass("opened");
    }, 1500);

    $(document).on("click", ".helpPopup__closeBtn", function() {
        $(".helpPopup").removeClass("opened");
    });
});
